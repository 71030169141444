import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlay, faArrowRight, faMicrochip, faRocket, faEarthAsia, faSnowflake, faMap, faPause } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import 'flowbite';
import { Drawer, initDropdowns } from 'flowbite';


export default function ModelPage() {
  const [model, setModel] = useState("kunyu");
  const [runtime, setRuntime] = useState("");
  const [runtimeList, setRuntimeList] = useState([]);
  const [map, setMap] = useState("China");
  const [mapList, setMapList] = useState(["China", "US"]);
  const [imageType, setImageType] = useState(null);
  const [imageTypeList, setImageTypeList] = useState([]);
  const [showHr, setShowHr] = useState(0);
  const [playing, setPlaying] = useState(false);
  const playTimer = useRef(null);
  const drawerRef = useRef(null);

  const HOURS = [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84,
    90, 96, 102, 108, 114, 120, 126, 132, 138, 144, 150, 156, 162, 168,
    174, 180, 186, 192, 198, 204, 210, 216, 222, 228, 234, 240];

    useEffect(() => {
      axios.get(
        "/api/regions"
      ).then(res => {
        setMapList(res.data.maps);
      });

      initDropdowns();
      const drawerEl = document.getElementById("drawer-swipe");
      drawerRef.current = new Drawer(drawerEl, {
        placement: "bottom",
        backdrop: true
      });

      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, []);

    useEffect(() => {
      if (model) {
        axios.get(
          "/api/runtimes",
          { params: {model} }
        ).then(res => {
          setRuntimeList(res.data.runtimes);
          if (res.data.runtimes.indexOf(runtime) === -1) {
            setRuntime(res.data.runtimes[0]);
          }
        });
      }
    }, [model]);

    useEffect(() => {
      axios.get(
        "/api/plots",
        { params: {model, region: map} }
      ).then(res => {
        setImageTypeList(res.data.plots);
        if (imageType === null || res.data.plots.filter(p => p.code === imageType.code).length === 0) setImageType(res.data.plots[0]);
      })
    }, [map]);

    useEffect(() => {
      if (playing) playTimer.current = setInterval(hrForward, 500, true);
      else clearInterval(playTimer.current);
    }, [playing]);

    const onKeyDown = e => {
      if (e.keyCode === 37) hrBackward();
      else if (e.keyCode === 39) hrForward();
    }

    const hrForward = (cycle=false) => setShowHr(hr => {
      let hrIndex = HOURS.indexOf(hr);
      if (hrIndex < HOURS.length - 1) return HOURS[hrIndex + 1];
      else if (hrIndex === HOURS.length - 1 && cycle) return HOURS[0];
      return hr;
    })

    const hrBackward = () => setShowHr(hr => {
      let hrIndex = HOURS.indexOf(hr);
      if (hrIndex > 0) return HOURS[hrIndex - 1];
      return hr;
    });

    const getImageSrc = () => {
      if (!model) return null;
      if (!runtime) return null;
      if (!map) return null;
      if (!imageType) return null;
      let hrPad = `${showHr}`.padStart(3, '0');
      let regionStr = map.replaceAll(' ', '_').toLowerCase();
      return `/media/${model.toLowerCase()}/${runtime}/${imageType.code.toLowerCase()}_${regionStr}_${hrPad}.png`;
    }

    const changeRuntime = rt => {
      let nowIdx = runtimeList.indexOf(runtime);
      let nextIdx = runtimeList.indexOf(rt);
      let timeChange = (nextIdx - nowIdx) * 12;
      let nextTime = showHr + timeChange;
      if (nextTime < 0) nextTime = 0;
      if (nextTime > 240) nextTime = 240;
      setRuntime(rt);
      setShowHr(nextTime);
    }

    const translateModel = model => model === "kunyu" ? "Kunyu" : "Kunyu-Legacy";

    //initDropdowns();

  return <section>
    <div className="py-4 px-4 mx-auto max-w-full text-center lg:py-6 lg:px-32">
      <div className="h-0 lg:h-4" />
      <div className="flex md:flex-row flex-col gap-4">
        <div className="md:basis-3/4 basis-full flex justify-center">
          <img class="h-auto max-w-full" src={getImageSrc()} />
        </div>
        <div className="md:basis-1/4 basis-full">
          <div className="flex flex-wrap gap-2 mb-4 justify-center lg:justify-normal">
            <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xl w-10 h-10 justify-center text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500" onClick={hrBackward}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xl w-10 h-10 justify-center text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500" onClick={() => setPlaying(state => !state)}>
              <FontAwesomeIcon icon={playing ? faPause : faPlay} />
            </button>
            <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xl w-10 h-10 justify-center text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500" onClick={hrForward}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
          <div className='flex flex-wrap gap-2 mb-8 min-h-[30%] justify-center lg:justify-normal'>
            {HOURS.map(hr => <button type='button' className={`border border-gray-400 ${showHr === hr ? "bg-blue-700 text-white" : "hover:bg-gray-200"} focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-md text-center inline-flex items-center w-10 h-10 justify-center`} onClick={() => setShowHr(hr)}>{hr}</button>)}
          </div>
          <div className='mb-4 flex flex-wrap gap-2 justify-center lg:justify-normal'>
            <button id="dropdownHoverButton" data-dropdown-toggle="dropdownHover" data-dropdown-trigger="click" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
              <FontAwesomeIcon icon={faMicrochip} className='mr-2' /> Model: {translateModel(model)}
            </button>
            <div id="dropdownHover" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                <li>
                  <a class="block px-4 py-2 font-medium hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setModel("kunyu")}>
                    <FontAwesomeIcon icon={faMicrochip} className='mr-2' /> Kunyu
                  </a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 font-medium hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setModel("kunyu-legacy")}>
                    <FontAwesomeIcon icon={faMicrochip} className='mr-2' /> Kunyu-Legacy
                  </a>
                </li>
              </ul>
            </div>
            <button id="dropdownHoverButtonRuntime" data-dropdown-toggle="dropdownHoverRuntime" data-dropdown-trigger="click" class="text-blue-700 bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
              <FontAwesomeIcon icon={faRocket} className='mr-2' /> Runtime: {runtime}
            </button>
            <div id="dropdownHoverRuntime" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                {runtimeList.map(rt => <li><a class="block px-4 py-2 font-medium hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => changeRuntime(rt)}>
                    <FontAwesomeIcon icon={faRocket} className='mr-2' /> {rt}
                  </a></li>)}
              </ul>
            </div>
            <button class="text-blue-700 bg-blue-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" onClick={() => drawerRef.current.toggle()}>
              <FontAwesomeIcon icon={faEarthAsia} className='mr-2' /> Region: {map}
            </button>
            <div ref={drawerRef} id="drawer-swipe" class={`fixed z-40 w-full overflow-y-scroll bg-white border-t border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform left-0 right-0 translate-y-full bottom-0 max-h-96`} tabindex="-1" aria-labelledby="drawer-swipe-label">
              <div class="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700" onClick={() => drawerRef.current.toggle()}>
                  <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
                  <h5 id="drawer-swipe-label" class="inline-flex items-center text-base text-gray-500 mt-2 dark:text-gray-400">
                    <FontAwesomeIcon icon={faEarthAsia} className='mr-2' />Select Region
                  </h5>
              </div>
              <div class="grid grid-cols-3 gap-4 p-4 lg:grid-cols-8">
                {mapList.map(region => <div
                  class="p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-gray-700"
                  onClick={() => {setMap(region);drawerRef.current.toggle()}}
                >
                  <div class="flex justify-center items-center p-2 mx-auto mb-2 max-w-[48px] rounded-full w-18 h-18">
                      <FontAwesomeIcon icon={faMap} />
                  </div>
                  <div class="font-medium text-center text-gray-500 dark:text-gray-400">{region}</div>
                </div>)}
              </div>
            </div>
            <button id="dropdownHoverButtonImageType" data-dropdown-toggle="dropdownHoverImageType" data-dropdown-trigger="click" class="text-blue-700 bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
              <FontAwesomeIcon icon={faSnowflake} className='mr-2' /> Param: {imageType?.name}
            </button>
            <div id="dropdownHoverImageType" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-80 dark:bg-gray-700">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                {imageTypeList.map(itype => <li><a class="block px-4 py-2 font-medium hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setImageType(itype)}>
                    <FontAwesomeIcon icon={faSnowflake} className='mr-2' /> {itype.name}
                  </a></li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
